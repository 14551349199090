import * as React from "react";
import { graphql, HeadFC } from "gatsby";

import Layout from "../../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../../components/layouts/ResponsiveBlock/ResponsiveBlock";

import { CommonHead } from "../../../components/commons/Head/Head";

import { KoreanDivisionAbstruct } from "../../../components/elements/DivisionAbstruct/DivisionAbstruct";
import Hr from "../../../components/elements/Hr/Hr";
import {
  KLHeader,
  KLBox,
  KLCardHeader,
  KLCard,
  KLNumberHeader,
  KLNumbers,
  KLButtons,
  KLSection1,
  KLSection2,
  KLSection3,
} from "../../../components/elements/KoreanLeaf/KoreanLeaf";
import LeftBar from "../../../components/elements/LeftBar/LeftBar";
import SnsShare from "../../../components/elements/SnsShare/SnsShare";
import Vimeo from "../../../components/elements/Vimeo/Vimeo";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  return (
    <Layout title={title} pageType="TopPage">
      <>
        {/* メイン動画 */}
        <Vimeo
          vimeoId="944314595"
          privacyHash="fcd24e7fa1"
          autoPlay
          controls
          muted
        />
        {/* 韓国語用リーフヘッダー */}
        <KLHeader
          bgColorClass="tw-bg-[#2BBFBF]"
          koreanTitle="이루다"
          japaneseTitle="叶う"
          anchorList={[
            { id: "watashinoyume", title: "私の夢" },
            { id: "ryuugaku", title: "留学" },
            { id: "shigoto", title: "仕事" },
          ]}
          imageSrc="/images/korean/kv‐realize.jpg"
        />
        <KLBox sentence="韓国語と英語の力で、将来の幅を無限に広げ、グローバルに活躍できる人材を育てる。" />
        <Hr />
        <LeftBar>
          <ResponsiveBlock bgColor="">
            <KLCardHeader title="私の夢" id="watashinoyume" />
            <KLCard
              bgColorClass="tw-bg-[#CBF7F7]"
              imageSrc="/images/korean/4-1-realize.JPG"
              title="夢の実現ができるJCFL"
              lines={[
                "趣味の韓国語からプロの韓国語の世界を目指す",
                "好きな韓国語から教養を高める",
                "使える韓国語でキャリア設計ができる",
              ]}
            />
            <KLCardHeader title="留学" id="ryuugaku" />
            <KLCard
              bgColorClass="tw-bg-[#CBF7F7]"
              imageSrc="/images/korean/4-2-realize.JPG"
              title="韓国の名門大学への編入率は毎年100%"
              lines={[
                "数多くの韓国の名門大学と提携",
                "学内でJCFL独自の大学編入説明会を実施",
                "編入のための授業を受講し、学校学部選択から出願までサポート",
                "JCFLの単位と現地の大学の単位と単位互換できるため、2年で卒業が可能",
                "韓国の大学卒業後の韓国や日本での就職サポートも受けられる",
              ]}
            />
            <KLCardHeader title="仕事" id="shigoto" />
            <KLCard
              bgColorClass="tw-bg-[#CBF7F7]"
              imageSrc="/images/korean/4-3-realize.JPG"
              title="韓国語と英語を武器に様々な業界への就職実現"
              lines={[
                "ビジネスマナーの授業を通して社会人基礎力をアップ",
                "年間約100社以上の学内企業セミナーを実施",
                "ー人でやる就活ではなく、担任や就職センターなど学校でサポート",
              ]}
            />
          </ResponsiveBlock>
        </LeftBar>
        <Hr />
        <ResponsiveBlock bgColor="">
          <KLSection2 />
          <KLSection3 />
        </ResponsiveBlock>
        <Hr />
        <ResponsiveBlock bgColor="">
          <KLNumberHeader title="数字で見る" id="suujidemiru" />
          <KLNumbers
            items={[
              {
                title: "就職率",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: true,
                number: "100",
                unitBack: "%",
                description:
                  "担任の先生や就職センターの先生が履歴書添削や面接練習など手厚くサポート。",
              },
              {
                title: "編入率",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: true,
                number: "100",
                unitBack: "%",
                description:
                  "滞りなく手続きが出来るよう担任の先生がサポート！編入準備に向けた授業も受講が可能。",
              },
            ]}
          />
          <KLNumbers
            items={[
              {
                title: "交換留学",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "約",
                unitSideSmaller: false,
                threeDigits: false,
                number: "3",
                unitBack: "ヶ月",
                description:
                  "ソウル・釜山・済州島で交換留学が可能です。現地の大学生との交流もあり、より深く韓国について知ることができます。",
              },
              {
                title: "短期留学",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "約",
                unitSideSmaller: false,
                threeDigits: false,
                number: "3",
                unitBack: "週間",
                description:
                  "ソウルにある大学に行き、レベル分けされたクラスで集中的に韓国語を学びます。現地の文化も体験できます。",
              },
            ]}
          />
        </ResponsiveBlock>
        <Hr />
        <KLButtons
          buttons={[
            { name: "出会う", url: "/korean/meet/" },
            { name: "感じる", url: "/korean/feel/" },
            { name: "学ぶ", url: "/korean/learn/" },
          ]}
        />
        <Hr />
        <SnsShare
          path="/korean"
          title={title}
          buttonName="戻る"
          buttonUrl="/korean"
          spaceBottom
        />
        <Hr />
        <KoreanDivisionAbstruct spaceBottom />
      </>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`叶う - 韓国語＋英語専攻 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="韓国語と英語の力で、将来の幅を無限に広げ、グローバルに活躍できる人材を育てる"
    ogImageFilepath="/images/korean/kv‐realize.jpg"
  >
    <title>
      叶う - 韓国語＋英語専攻 - {data.allSettingsJson?.nodes[0].siteTitle}
    </title>
  </CommonHead>
);
